export function scrollanimation(){
  if(document.querySelector('.j-animation')){

    let controller = new ScrollMagic.Controller();
    const sceneAdd = (element) => {
      let scene = new ScrollMagic.Scene({triggerElement: element, triggerHook: .7,})
      .on('enter', ()=>{
        element.classList.add('action');
      })
      .addTo(controller);
    }

    const animation = document.querySelectorAll('.j-animation');

    animation.forEach((el, i) => {
      sceneAdd(el);
    });

  }
}
