export function header(){
  if (window.innerWidth < 769){
    //header nav
    const headerBtn = document.querySelector('#headerBtn');
    const activeCont = document.querySelectorAll('.js-header');

    headerBtn.addEventListener('click', ()=>{
      [...activeCont].forEach(cont => {
        cont.classList.toggle('active');
      });
    });

    if(document.querySelector('.headerInner')){
      document.querySelector('.headerInner').addEventListener('click', ()=>{
        [...activeCont].forEach(cont => {
          cont.classList.remove('active');
        });
      });
    }
  }
}
